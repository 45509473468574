function filterTabs() {
    const tabs = $('[data-sort]');
    const tabBurger = $('.filter-drop');
    let sortFilters = [];

    tabBurger.on('change', function () {
        let labelAttr = $(this).attr('id');
        $('label[for="' + labelAttr + '"]').toggleClass('open')
        // $(this).next().toggleClass('open');
        $(this).nextAll('.filter-tabs-list').toggle()
    })

    tabs.on('click', function () {
        let tab = $(this);
        let tabSort = tab.attr('data-sort').toLowerCase();
        let hideElem = true;

        if (tab.hasClass('active')) {
            tab.removeClass('active');
            sortFilters = sortFilters.filter((n) => { return n != tabSort });
        } else {
            tab.addClass('active')
            sortFilters.push(tabSort);
        }

        if (tab.closest('[data-filter-wrap]').find('[data-card-sort]').parent().attr('class').includes('col-')) {
            hideElem = tab.closest('[data-filter-wrap]').find('[data-card-sort]').parent();
        } else {
            hideElem = tab.closest('[data-filter-wrap]').find('[data-card-sort]')
        }

        if (sortFilters.length == 0) {
            hideElem.show()
            return;
        } else {
            hideElem.hide()
        }

        for (var i = sortFilters.length - 1; i >= 0; i--) {
            let filElements = tab.closest('[data-filter-wrap]').find('[data-card-sort]');

            filElements.each(function() {
                let $this = $(this),
                    thisFilters = $this.attr('data-card-sort').toLowerCase();
                
                if (thisFilters.includes(sortFilters[i])) {
                    if ($this.parent().attr('class').includes('col-')) {
                        $this.parent().show()
                    } else {
                        $this.show()
                    }
                }
            })
        }
    })

    checkFilterTabs();
}

function checkFilterTabs() {
    let selectors = {
        defaultClass: '.default-active'
    }
    
    if ($(selectors.defaultClass).length > 0) {
        $(selectors.defaultClass).trigger('click');
    }
}

function languageDrop() {
    let selectors = {
        container: "#js-language-select",
        languageText: '.selected__text',
        languageList: '.language__list',
        languageItem: '.language__item',
        addedClass: 'active'
    }

    /* $(selectors.container).on('click', function () {
        $(this).find(selectors.languageList).toggle();
    }) */
    $(selectors.languageItem).on('click', function () {
        let $this = $(this);
        if (!$this.hasClass(selectors.addedClass)) {
            let newLanguage = $this.text();
            $(selectors.languageItem).removeClass(selectors.addedClass);
            $this.addClass(selectors.addedClass);
            $(selectors.languageText).text(newLanguage);
        }
    })
}

function anchorLinks() {
    $('a').on('click', function (e) {
        let body = $("html, body");
        let regexp = /^#.+/;
        if($(this).attr('href').match(regexp)) { 
            e.preventDefault();
            let elId = $(this).attr('href');
            if ($(elId).length > 0) {
                let element = $(elId);
                $(body).animate({
                    'scrollTop': element.offset().top
                }, 500);
            }
        }
    })
}

function mobileHeader() {
    let selectors = {
        header: ".header",
        headerAddedClass: "header-burger-active",
        bodyAddedClass: "mobile-menu-open",

        burger: ".header__menu-toggle",
        burgerAddedClass: "active",

        toggleHeader: ".toggle-header-menu",
        toggleHeaderAddedClass: "active"
    }

    $(selectors.burger).on('click', function () {
        let $this = $(this);
        if ($this.hasClass(selectors.burgerAddedClass)) {
            $this.removeClass(selectors.burgerAddedClass);
            $(selectors.header).removeClass(selectors.headerAddedClass);
            $('body').removeClass(selectors.bodyAddedClass);
        } else {
            $this.addClass(selectors.burgerAddedClass);
            $(selectors.header).addClass(selectors.headerAddedClass);
            $('body').addClass(selectors.bodyAddedClass);
        }
    })
    $(selectors.toggleHeader).on('click', function () {
        let $this = $(this);
        $this.toggleClass(selectors.toggleHeaderAddedClass);
    })
}

function testimonialsSlider() {
    let $testimonialsSlider = $('.testimonials .slider');
    if ($testimonialsSlider) {
        $testimonialsSlider.slick({
            dots: false,
            arrows: true,
            infinite: false,
            autoplay: false,
            autoplaySpeed: 2000,
            speed: 500,
            fade: false,
            cssEase: 'ease-in-out',
            adaptiveHeight: true,

            prevArrow: $('.testimonials .prev'),
            nextArrow: $('.testimonials .next'),
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        infinite: true,
                        fade: true
                    }
                }
            ]
        });
    }
}

function latestWorkSimleSlider() {
    let $latestWorkSimleSlider = $('.latest-work-slider-simple .slider');
    if ($latestWorkSimleSlider) {
        $latestWorkSimleSlider.slick({
            dots: false,
            arrows: true,
            infinite: false,
            autoplay: false,
            autoplaySpeed: 2000,
            speed: 500,
            fade: false,
            cssEase: 'ease-in-out',
            adaptiveHeight: false,

            prevArrow: $('.latest-work-slider-simple .prev'),
            nextArrow: $('.latest-work-slider-simple .next'),
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        infinite: true,
                        speed: 1000,
                        fade: true,
                    }
                }
            ]
        });
    }
}

function weSupportSlider() {
    
    let supportSlider = $('.we-support__slider');
    if (supportSlider.length > 0) {
        supportSlider.slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            mobileFirst: true, 
            responsive: [
            {
                breakpoint: 992,
                settings: "unslick"
            }
            ]
        });
    }
}

function teamSlider() {
    let $status = $('.team-slider-status');
    let teamSlider = $('.team-slider');

    if ($status.length > 0) {
        teamSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            if (!slick.$dots) {
                return;
            }

            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + '/' + (slick.$dots[0].children.length));
        });
    }

    if (teamSlider.length > 0) {
        teamSlider.slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            prevArrow: $('.team-slider-nav .prev'),
            nextArrow: $('.team-slider-nav .next')
        });
    }
}

function projectPageSlider() {
    let $projectpageSlider = $('.project-page .project__slider');
    let $sliderThumbnails = $('.project-page .slider-nav-thumbnails');
    if($projectpageSlider.length) {
        $projectpageSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: false,
            asNavFor: '.project-page .slider-nav-thumbnails',
        });

        // $projectpageSlider.slick('refresh');

        // let $num = $('.project-page .slider-nav-thumbnails .slide').length;

        if($sliderThumbnails.length) {
            $sliderThumbnails.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.project-page .project__slider',
                arrows: false,
                fade: true,
                dots: false,
                focusOnSelect: true
            });

            // $sliderThumbnails.slick('refresh');
        }
    
        

    }

    $('.fullscreen-toggle').on('click', function (e) {
        e.stopPropagation();
        let $sliderWrap = $(this).closest('.slider__wrap');
        let $projectSlider = $('.project__slider');
        let $thumbnailSlider = $('.slider-nav-thumbnails');
        if($sliderWrap.attr('data-fullscreen') == 'false') {
            $sliderWrap.attr('data-fullscreen', 'true');
            $('body').css('overflow', 'hidden');
            if($projectSlider.length && $thumbnailSlider.length) {
                setTimeout(function () {
                    $projectSlider.slick('refresh');
                    $thumbnailSlider.slick('refresh');
                });
            }
        
        } else {
            $sliderWrap.attr('data-fullscreen', 'false');
            $('body').css('overflow', 'unset');
            if($projectSlider.length && $thumbnailSlider.length) {
                setTimeout(function () {
                    $projectSlider.slick('refresh');
                    $thumbnailSlider.slick('refresh');
                });
            }
        }
    })
    
}

function showThankYou() {
    const $popup = $('#jsThankYou');
    let $allPopup = $('.popup-wrapper');
    let $closePopup = $('.close-popup');

    $allPopup.removeClass('show');
    
    if ($popup.length > 0) {
        $popup.addClass('show');
        $('body').css({
            "overflow": "hidden"
        })
    }
}

function callPopup() {
    let selectors = {
        callBtn: '[data-call-popup]',
        getId: 'data-call-popup'
    }

    let $popup = $('.popup-wrapper');
    let $closePopup = $('.close-popup');

    if ($closePopup.length > 0) {
        $closePopup.on('click', function() {
            let $this = $(this);
            $this.closest('.popup-wrapper').removeClass('show');
            $('body').css({
                "overflow": "visible"
            })
        })
    }

    /* $(document).click( function(e){
        if ( $(e.target).closest('.popup-content').length ) {
            return;
        }
        $popup.removeClass('show');
        $('body').css({
            "overflow": "visible"
        })
    }); */

    if ($(selectors.callBtn).length > 0) {
        $(selectors.callBtn).on('click', function(evt) {
            evt.preventDefault();
            
            let $this = $(this),
                popupID = $this.attr(selectors.getId);

            if($(popupID).length > 0) {
                $(popupID).addClass('show');
            }
        })
    }
}

function subminContactForm() {
    let $form = $('[data-contact-form]');

    if ($form.length > 0) {
        $form.on('submit', function (event) {
            event.preventDefault();
            showThankYou();
        })
    }
}

function brandSlider() {
    let $brandSlider = $('.brand-slider');

    if ($brandSlider.length > 0) {
        $brandSlider.slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            centerMode: false,
            centerPadding: '0',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    centerMode: true,
                    centerPadding: '25%',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                  }
                }
            ]
        })
    }
}

$(document).ready(function () {
    let $window = $(window), 
        $card = $('.brand-slider'), 
        toggleSlick;
  
    toggleSlick = function () {
      if ($window.width() < 768) {
        $card.slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            centerMode: false,
            centerPadding: '0',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    centerMode: true,
                    centerPadding: '60px',
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    centerMode: true,
                    centerPadding: '25%',
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
      } else {
        $card.slick('unslick');
      }
    }
});

function servicesCard() {

    let windowType;
    let $wind = $(window);
    let $cardsRow = $('.section.services');

    let desktopFunctions = function() {
        $cardsRow.addClass('desktop');
        $cardsRow.removeClass('mobile');

        let $card = $('.desktop .services-card');
        $card.click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).addClass('active col-lg-8');
            $(this).find('.card-body-wrap').slideDown();
            $(this).siblings().removeClass('active col-lg-8');
            $(this).siblings().find('.card-body-wrap').slideUp();
        });
    }

    let mobileFunctions = function() {
        $cardsRow.removeClass('desktop');
        $cardsRow.addClass('mobile');
        let $card = $('.mobile .services-card');
        $card.click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).siblings().find('.card-body-wrap').slideUp();
            $(this).find('.card-body-wrap').slideDown();
        });

    }

    let mobileCheck = function() {
        let window_w = $wind.width();
        let currType = window_w < 991 ? 'mobile' :'desktop';

        if (windowType == currType) {
            return;
        } else {
            windowType = currType;
        }

        if (windowType == 'mobile') {                
            mobileFunctions();
        } else {
            desktopFunctions();
        }
    }

    mobileCheck();
    let resizeTimer;

    $wind.resize(function() {
        if (resizeTimer) {
            clearTimeout(resizeTimer);
        }

        resizeTimer = setTimeout(mobileCheck, 300)
    });
   
}

function helpCards() {
    let $card = $('.help-card');
    let $cardsHeight;

    function mobileCards() {
        $card.on('click', function () {
            $(this).siblings().removeClass('active col-12 col-md-6');
            $(this).addClass('active col-12 col-md-6');
        });
    }

    function desktopCards() {
        let $cardWidth = $card.outerWidth();
        let $cardDescription = $card.find('.card__description');
        let $cardOutherHeight = $card.outerHeight();
        if(!$cardsHeight) {
            $cardsHeight = $cardOutherHeight;
            $card.css('maxHeight', $cardsHeight);
        }
        $cardDescription.css('width', ($cardWidth * 2) - 3);
        $card.on('click', function () {
            $(this).siblings().removeClass('active col-12 col-lg-6');
            $(this).addClass('active col-12 col-lg-6');
        });
    }

    checkWindowWidth();

    function checkWindowWidth() {
        if($card.length) {
            if(window.innerWidth >= 992) {
                desktopCards();
            } else {
                mobileCards();
            }
        }
    }

    window.addEventListener('resize', function() {
        checkWindowWidth();
    })
}

function projectSlider() {
    let selectors = {
        mainSlider: '.project-slider',
        thumbSlider: '.project-thumbs',
        thumbSliderElem: '.slide',
        sliderNav: '.project-slider-nav',
    }

    if($(selectors.mainSlider).length > 0 && $(selectors.thumbSlider).length > 0) {

        let $status = $('.project-slider-status');
        let projectSlider = $(selectors.mainSlider);

        if ($status.length > 0) {
            projectSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                if (!slick.$dots) {
                    return;
                }

                var i = (currentSlide ? currentSlide : 0) + 1;
                $status.text(i + '/' + (slick.$dots[0].children.length));
            });
        }
        
        $(selectors.mainSlider).slick({
            adaptiveHeight: true,
            infinite: false,
            dots: true,
            prevArrow: $('.project-slider-nav .prev'),
            nextArrow: $('.project-slider-nav .next')
        });

        $(selectors.mainSlider).on('afterChange', function(event, { slideCount: count }, currentSlide, nextSlide){
            $(selectors.thumbSlider + ' ' + selectors.thumbSliderElem).removeClass('active');
            $(selectors.thumbSlider).children().eq(currentSlide).addClass('active');
        });

        $(selectors.thumbSlider + ' ' + selectors.thumbSliderElem).on('click', function() {
            $(selectors.thumbSlider + ' ' + selectors.thumbSliderElem).removeClass('active');
            $(this).addClass('active');
            let slickIndex = $(selectors.thumbSlider + ' ' + selectors.thumbSliderElem).index($(this));
            $(selectors.mainSlider).slick('slickGoTo', slickIndex);
        })
    }
}

function ourVacancyScroll() {

    let $section = $('section.our-vacancy');
    if($section.length) {
        equalHeight();

        $(window).on('resize', equalHeight);
    }
    
    function equalHeight() {

        let $filterList = $section.find('.filter-list');
        
        if(window.innerWidth >= 768) {
            $filterList.css('maxHeight', '0');
            let $contentColumn = $section.find('.content-column');
            let $tabs = $section.find('.filter-tabs-wrap');
            let $tabsHeight = $tabs.innerHeight() + Number($tabs.css('marginBottom').replace(/px/, '')) + Number($tabs.css('marginTop').replace(/px/, ''));
            let $contentColumnHeight = $contentColumn.innerHeight();
            $filterList.css('maxHeight', $contentColumnHeight - $tabsHeight + 'px');
        } else {
            $filterList.css('maxHeight', 'unset');
        }
        
    }


}

function articleCards() {
    let $articleCard = $('.article-card');
    
    if($articleCard.length) {
        if(window.innerWidth >= 992) {
            $('.article-card').on('click', function () {
                $(this).addClass('active');
                $(this).siblings().removeClass('active');
            })
        }
    }
}

function inputFiles() {
    var inputs = document.querySelectorAll( '.inputfile' );
    Array.prototype.forEach.call( inputs, function( input )
    {
        var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener( 'change', function( e )
        {
            var fileName = '';
            if( this.files && this.files.length > 1 ) {
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            } else {
                fileName = e.target.value.split('\\').pop();
            }

            if( fileName ) {
                label.parentElement.querySelector( '.file-name' ).innerHTML = fileName;
                label.parentElement.classList.add('has-file');
            } else {
                label.innerHTML = labelVal;
            }
        });
    });
}

function maskInput() {
    let phoneMask = $('input.phone-mask');
    let nameMask = $('input[name="name"]');

    nameMask.on('keyup', function(e) {
        let $this = $(this);
        let thisVal;
        
        thisVal = $this.val();
        
        if ((e.keyCode >= 48 && e.keyCode <= 57) && e.keyCode != 32) {
            $this.val(thisVal.slice(0, -1));
        }
    })

    let im = new Inputmask("+99 (999) 999 9999");
    im.mask(phoneMask);
}

function getCheckForm() {
    let selectors = {
        forms: '.js-check-form'
    }

    if ($(selectors.forms).length > 0) {
        checkForm(selectors.forms);
        $(selectors.forms).on('change', function() {
            checkForm(selectors.forms);
        })
    }
}

function checkForm(forms) {
    $(forms).each(function(index, form) {
        let $this = $(this);
        let requiredElements = $this.find('[required]');
        let requiredElementsLength = requiredElements.length;
        let emptyFields = 0;
        
        requiredElements.each(function(index, reqItem) {
            let $this = $(this);
            if ($this.val() == '') {
                emptyFields += 1;
            }
        })

        if (emptyFields == 0) {
            $this.find('[type="submit"]').removeAttr('disabled');
        } else {
            $this.find('[type="submit"]').prop('disabled', 'disabled');
        }
    })
    return;
}

function fieldWithLabel() {
    let selectors = {
        fields: '.field-with-label',
        addedClass: 'active',
        fieldInput: '.field-label',
    }

    if ($(selectors.fields).length > 0) {
        $(selectors.fields).find(selectors.fieldInput).on('focus', function() {
            let $this = $(this);
            $this.closest(selectors.fields).addClass('active');
        })

        $(selectors.fields).find(selectors.fieldInput).on('blur', function() {
            let $this = $(this),
                inputVal = $this.val();

            if (inputVal.length == 0) {
                $this.closest(selectors.fields).removeClass('active');
            }
        })
    }
}

function mainScrollAnimate() {
    var flag = true;
    var blockToScroll = $('.on-scroll');
    var winScroll = $(window).scrollTop();
    var winHeight = $(window).height();
    if (blockToScroll.length > 0) {
        $(window).on('scroll load', function(){
            var winScroll = $(window).scrollTop();
            var winHeight = $(window).height();
            $('.on-scroll').each(function(){
                var flag2 = true;
                var $this = $(this);
                var ofsetShow = $this.attr('data-offset');
                var getClass = $this.attr('data-animate-class');
                var ofsetBlock = $(this).offset().top;
                var coefficient = ofsetShow !== undefined ? ofsetShow : 0.85;
                var addedClass = getClass !== undefined ? getClass : 'show-scroll';
                if (winScroll > ofsetBlock - winHeight * coefficient && flag){
                    $this.addClass(addedClass);
                    removeAnimateClass($this, addedClass);
                }
            })
        })
    }
}

function removeAnimateClass(elem, classes) {
    setTimeout(function() {
        elem.removeClass('on-scroll');
        elem.removeClass(classes);
    }, 1300)
}

function addDelay() {
    var block = $('[data-delay]'), classDel, $this, arrayLength, countDown;
    block.each(function(i, elem) {
        countDown = 0;
        $this = $(this);
        classDel = $this.attr('data-delay');
        arrayLength = $this.find(classDel).length;
        for (var i = countDown; i <= arrayLength; i++) {
            $this.find(classDel).eq(i).css({
                'transition-delay' : i*0.2+'s'
            });
        }
    })
}

$(window).on('load', function () {
    filterTabs();
    languageDrop();
    anchorLinks();
    mobileHeader();
    testimonialsSlider();
    weSupportSlider();
    teamSlider();
    subminContactForm();
    brandSlider();
    latestWorkSimleSlider();
    servicesCard();
    helpCards();
    projectSlider();
    ourVacancyScroll();
    articleCards();
    projectPageSlider();
    inputFiles();
    maskInput();
    callPopup();
    getCheckForm();
    fieldWithLabel();
    mainScrollAnimate();


    $(document).on('keydown', function(e) {
        if (e.keyCode == 27) {
            $('.popup-wrapper').removeClass('show');
        }
    });
});